@import "../../colours.scss";
.ContactMain {
    background-color: $background-color;
    width: 75vw;
    height: 45vh;
    margin-top: 15vh;
    margin-bottom: 10vh;
    
  
  .ContactInner{
  
    width: 50vw;
    display:flex;
    align-items: flex-start;
    flex-direction: column;
    margin-left: 20vw;
    margin-top: 5vh;
    font-family: 'Ubuntu', sans-serif;
  
    h1 {
        
        font-size: clamp(2rem, 2.5vw, 3rem);
        color: $secondary-color;
      }
  
    p{
      color: $secondary-color;
      font-size: clamp(1rem, 2vw, 2rem);
    }
  
    a{
      color: $primary-color;
    }
  
  }
  
}
  
  