@import '../../colours.scss';

.AboutMain 
{
    background-color: $background-color;

    .AboutContent {
        display: flex;
        flex-direction: row;
        font-family: 'Ubuntu', sans-serif;

        p {
        color: $secondary-color;
        font-size: clamp(1rem, 1.5vw, 2rem);
        text-align: justify;
        text-justify: inter-word;
        }

        img {
            width: 30vw;
        }
        
        strong{
            color: $primary-color;
        }

        hr{
            color: $primary-color;
        }
    }
}
  