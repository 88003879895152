@import 'colours';

html, body{
    background-color: $background-color;   
    margin: 0;
    padding: 0;
    height: 100%;
}

.App{
    background-color: $background-color;
    
    h1{
        color: $primary-color;
    }

    p{
        color: $secondary-color;
    }
   
    .custom-container
    {
        width: 75vw;

        .row
        {
            margin-bottom: 10vh;
        }
    }
}
