@import "../../colours.scss";

.video{
    width: 100%;
    height: 100%;
}

.projectLeft{
    font-family: 'Ubuntu', sans-serif;
    h3{
        text-align: right;
        color: $primary-color;
    }

    p{
        margin-top: 2vh;
        text-align: justify;
        color: $secondary-color;
        font-size: clamp(1rem, 1.5vw, 2rem);
    }

    strong{
        color: $primary-color;
    }

    code{
        color: $primary-color;
    }

    hr{
        
        color: $primary-color;
    }

    .itemImage{
        width: 30vw;
    }

    .links{
        display: flex;
        flex-direction: column;
        margin-top: 5vh;
        .icon{
            align-self: flex-end;
        }

        margin-bottom: 4vh;
    }
}

.projectRight{
    font-family: 'Ubuntu', sans-serif;
    h3{
        text-align: left;
        color: $primary-color;
    }

    p{
        margin-top: 2vh;
        text-align: justify;
        color: $secondary-color;
        font-size: clamp(1rem, 1.5vw, 2rem);
    }

    strong{
        color: $primary-color;
    }

    code{
        color: $primary-color;
    }

    hr{
        color: $primary-color;
    }

    // .itemImage{
    //     width: 30vw;
    // }

    .links{
        display: flex;
        flex-direction: column;
        margin-top: 5vh;
        .icon{
            align-self: flex-start;
        }

        margin-bottom: 4vh;
    }

}
