@import "../../colours.scss";

.sectionTitleRight {
    display: flex;
    flex-direction: row;
    justify-content: end;
  }
  
  .sectionTitleLeft {
    display: flex;
    flex-direction: row;
    justify-content: start;
  }
  
  .numberTitle {
    color: $primary-color;
    font-family: 'Nova Mono', monospace;
  }
  
  .wordTitle {
    margin-left: 2vw;
    color: $secondary-color;
    font-weight: bolder;
    font-family: 'Ubuntu', sans-serif;
  }
  