@import '../../colours.scss';

.rightSideNav {
    font-family: 'Ubuntu', sans-serif;
    position: fixed;
    bottom: 10vh;
    right: -4vw;
    
  
    .emailAddress {
      transition: 0.2s ease-in-out ;
      transform: matrix(0, 1, -1, 0, -32, -4);
      margin-bottom: 50px;
      color: white;
    }

    .emailAddress:hover{
      transition: 0.2s ease-in-out ;
      color: white;
      transform: matrix(0, 1, -1, 0, -32, -8);
    }
  
    .vl-right {
      border-left: 1px solid $primary-color;
      height: 15vh;
      transform: matrix(1, 0, 0, 1, 40, 0);
      margin-bottom: -10vh;
    }
  }
  
    @media screen and (max-width: 1050px){
      .rightSideNav{
        position: absolute;
        left: -1000vw;
      }
    }
  