@import '../../colours.scss';

.custom-navbar{
  background-color: $background-color;
}

.NavRight {
    display: flex;
    justify-content: flex-end;
    margin-left: auto;
}

.NavText {
    font-size: 14px;
    display: flex;
    flex-direction: row;
    transition: 0.2s;

    font-family: 'Nova Mono', monospace;
    cursor: pointer;

    .LinkNumber
    {
        color: $primary-color;
    }

    .LinkName 
    {
      color: $secondary-color;
      margin-left: 10px;
      margin-right: 5px;
      font-family: 'Ubuntu', sans-serif;
    }
  }
  .NavText:hover 
  {
    .LinkName
    {
      transition: 0.2s;
      color: $primary-color;
    }
  }

@media screen and (max-width: 1000px) {
  .custom-navbar{
    background-color: $secondary-color;
  }

  .NavText {
    font-size: 14px;
    display: flex;
    flex-direction: row;
    transition: 0.2s;

    font-family: 'Nova Mono', monospace;
    cursor: pointer;

    .LinkNumber
    {
      color: $primary-color;
    }

    .LinkName 
    {
      color: $background-color;
    }
  }
}