@import '../../colours.scss';

.ProjectMain 
{
    background-color: $background-color;

    .ProjectContent {
        display: flex;
        flex-direction: row;
        font-family: 'Ubuntu', sans-serif;

        p {
            color: $secondary-color;
            font-size: clamp(1rem, 1.5vw, 2rem);
            text-align: justify;
            text-justify: inter-word;
        }

        img {
            width: 30vw;
        }
    }
}