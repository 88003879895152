@import '../../colours.scss';

.WorkMain 
{
    background-color: $background-color;

    .WorkContent {
        display: flex;
        flex-direction: row;
        font-family: 'Ubuntu', sans-serif;

        p {
            color: $secondary-color;
            font-size: clamp(1rem, 1.5vw, 2rem);
            text-align: justify;
            text-justify: inter-word;
        }

        .image {
            width: 30vw;
        }

        strong{
            color: $primary-color;
        }

        .icon {
            display: flex;
            justify-content: flex-start;
        }

        hr{
            color:$primary-color;
        }

    }
}
  