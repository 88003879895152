@import '../../colours.scss';

.leftSideNav {
    position: fixed;
    bottom: 10vh;
    left: 2vw;
  
    .navlinkleft {
      margin-bottom: 10px;
    }
  
    .vl-left {
      border-left: 1px solid $primary-color;
      height: 15vh;
      margin-left: 27px;
      margin-bottom: -10vh;
    }
  
    img{
      transition: 0.2s ease-in-out;
    }
    
  
    img:hover{
      transition: 0.2s ease-in-out;
      transform: translateY(-3px);
      
    }
  }
  
    @media screen and (max-width: 1050px){
      .leftSideNav{
        position: absolute;
        left: -30vw;
      }
    }
  