@import '../../colours.scss';

.headlineContainer {
    margin: 11vw;
    margin-top: 80px;
  
    h1 {
      color: $primary-color;
      font-size: 7vw;
      font-family: 'Prompt', sans-serif;
    }
  
    .things {
      color: $secondary-color;
      font-family: 'Prompt', sans-serif;
    }
  
    p {
      color: $secondary-color;
      font-size: clamp(1rem, 2vw, 2rem);
    }
  
    .des-text {

        font-size: clamp(1rem, 2vw, 2rem);
        color: $secondary-color;
        margin-top: 3vh;
        width: 50vw;
        height: 30vh;
        text-align: justify;
    }
  }
  
  @media screen and (max-width: 750px) {
    .headlineContainer {
      margin: 8vw;
      margin-top: 60px;

      .des-text {
        font-size: 3.5vw;
        margin-top: 3vh;
        width: 80vw;
        height: 30vh;
      }

      h1{
        font-size: 11vw;
      }
      
    }
  }
  