// $primary-color: #e1e9e7;
// $secondary-color: #7faea6;
// $background-color: #32263c;

// $primary-color: #ffe9cb;
// $secondary-color: #b47547;
// $background-color: #262b36;

// $primary-color: #ad9e7c;
// $secondary-color: #fcfbfc;
// $background-color: #28252b;

// $primary-color: #956ed1;
// $secondary-color: #b58eff;
// $background-color: #000100;

// Best so far:
$primary-color: #f57930;
$secondary-color: #e7dcd9;
$background-color: #20242f;

// $primary-color: #d90030;
// $secondary-color: #262728;
// $background-color: #fdffff;

// $primary-color: #a6a8ae;
// $secondary-color: #fffdff;
// $background-color: #193d4f;
