@import '../../colours.scss';

.SkillsMain 
{
    background-color: $background-color;

    .SkillsContent {
        font-family: 'Ubuntu', sans-serif;
        display: flex;
        flex-direction: row;

        p {
        color: $secondary-color;
        font-size: clamp(1rem, 1.5vw, 2rem);
        text-align: justify;
        text-justify: inter-word;
        }

        img {
            width: 30vw;
        }

        strong{
            color: $primary-color;
        }

        hr{
            color: $primary-color;
        }
    }
}
  